import WarningBox from "../../component/warningBox";
//获取我的行程类型
import consumer_journey_journeyType from '@/lib/data-service/haolv-default/consumer_journey_journeyType'
// 我的行程V2
import consumer_journey_travelJourneyList from '@/lib/data-service/haolv-default/consumer_journey_travelJourneyList'
//获取我的行程列表
import getMyJourneyList from '@/lib/data-service/haolv-default/consumer_journey_myJourneyList'
import moment from "moment";

export default {
    data() {
        return {
            dateArr: [],
            params: {
                startDate: '',
                endDate: '',
                journeyType: 0
            },
            journeyTypeList: [],
            myJourneyList: [],
            loading: false
        }
    },
    components: {
        WarningBox
    },
    mounted() {
        this._getMyJourneyList();
        this._getMyJourneyType();
    },
    watch: {
        dateArr(newVal) {
            let [start, end] = newVal
            this.params.startDate = start ? start + ' 00:00:00' : ''
            this.params.endDate = end ? end + ' 23:59:59' : ''
        }
    },
    methods: {
        filterWeek (val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        async _getMyJourneyList() {
            this.loading = true;
            let res = await consumer_journey_travelJourneyList({});
            this.myJourneyList = res.datas.journeyList;
            this.loading = false;
        },
        async _getMyJourneyType() {
            let res = await consumer_journey_journeyType();
            this.journeyTypeList = res.datas;
        },
        filterMyJourneyList(journeyType) {
            this.params.journeyType = journeyType;
            this._getMyJourneyList();
        },
        toJourneyDetail(item) {
            if (item.businessType == 1) {
                this.$router.push({
                    name: 'admin-my-order-flight-detail',
                    query: {
                        orderNo: item.orderNo,
                    }
                })
            } else if (item.businessType == 2) {
                this.$router.push({
                    name: 'admin-my-order-train-detail',
                    query: {
                        orderNo: item.orderNo,
                    }
                })
            } else if (item.businessType == 3) {
                this.$router.push({
                    name: 'admin-hotel-order-detail',
                    query: {
                        orderNo: item.orderNo,
                    }
                })
            } else if (item.businessType == 4) {
                this.$router.push({
                  name: 'admin-my-order-car-detail',
                  query: {
                    orderNo: item.orderNo,
                  }
                })
            }
        }
    }
}
